.infoblock {
  padding-bottom: 80px;
  
  @include media() {
    padding-bottom: 40px;
  }

  &__line {
    border-bottom: 1px solid $color_nativno_7;
    margin-top: 30px;
    margin-bottom: 50px;

    @include media() {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 30.09px;
    text-transform: uppercase;
    margin-right: 230px;
    flex: 1 1;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;

    @include media() {
      font-size: 16px;
      line-height: 15.04px;
      margin-right: -120px;
    }

    &-action { 
      @include media() {
        margin-top: 15px;
      }
    }
  }
  
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-two-row {
      @include media() {
        // flex-direction: column;
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &-sort {
      display: flex;
      align-items: center;

      &-title {
        margin-right: 20px;
        line-height: 15.04px;
        font-size: 16px;
        color: $color_nativno_8;
      }
    }

    &-tab {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 15.04px;
      margin-left: 40px;

      @include media() {
        font-size: 11px;
        line-height: 10.34px;
        margin-top: 5px;
        margin-left: 0;
      }
      
      &-item {
        color: $color_nativno_6;
        margin-right: 20px;
        display: flex;
        
        @media (pointer: fine) {
          cursor: pointer;
        }
        
        @include media() {
          margin-right: 9px;
        }

        // &:nth-child(2) {
        //   margin: 0 20px;
        // }
        &:last-child {
          margin: 0;
        }
        
        &_active {
          color: $color_nativno_4;
          // padding-bottom: 28px;
          position: relative;
          
          &::after {
            content: '';
            position: absolute;
            top: 52px;
            border-bottom: 2px solid $color_nativno_2;
            width: 100%;
            left: 0;

            @include media() {
              top: 25px;
            }
          }
        }

        @media (pointer: fine) {
          &:hover {
            color: $color_nativno_4;
          }
        }


        &-icon {
          width: 16px;
          max-height: 16px;

          path.ic-fill, g.ic-fill {
            fill: $color_nativno_6;
          }

          path.ic-stroke {
            stroke: $color_nativno_6;
          }

          &_active {
            path.ic-fill, g.ic-fill {
              fill: $color_nativno_4;
            }
            path.ic-stroke {
              stroke: $color_nativno_4;
            }
          }
        }

      }
    }
  }

  &__sort {
    position: relative;
    // min-width: 170px;
    // margin-right: 15px;
    
    @include media() {
      font-size: 11px;
      line-height: 10.34px;
      min-width: 115px;
      margin-right: 0;
    }

    &-selected {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: fit-content;
      font-weight: 500;
      
      @include media() {
        font-weight: 700;
        margin-top: 10px;
      }

      svg {
        margin-left: 7px;
        
        @include media() {
          margin-left: 5px;
          width: 10px;
          height: 10px;
        }
      }
    }

    &-items {
      position: absolute;
      top: 54px;
      background-color: rgba(0, 0, 0, 0.85);
      box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.12);
      border-radius: 0px 0px 10px 10px;
      z-index: 1;
      
      @include media() {
        top: 25px;
      }
    }
    
    &-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      white-space: nowrap;
      height: 40px;
      font-size: 14px;
      line-height: 13.16px;
      color: $color_nativno_4;
      padding: 0 15px;

      @include media() {
        font-size: 11px;
        line-height: 10.34px;
      }

      @media (pointer: fine) {
        &_active, &:hover {
          background-color: $color_nativno_2;
        }
      }

      &:last-child {
        border-radius: 0px 0px 10px 10px;
      }
    }
  }

  &__statistics &__line {
    @include media() {
      margin: 12px 0;
    }
  }
  &__statistics &__title-action {
    @include media() {
      margin-top: 0;
    }
  }
  &__statistics &__header-tab-item_active::after {
    @include media() {
      top: 29px;
    }
  }

  &.verticals-block {
    .infoblock__title-action {
      @include media() {
        width: 100%;
      }
    }
  }
}

.description-block {
  margin-bottom: 40px;
  align-self: flex-start;
  
  &__top {
    display: flex;
    align-items: center;
    margin: 50px 0 30px 0;
    
    @include media() {
      align-items: flex-start;
      margin: 10px 0 15px 0;
    }
  }
  &__comeback {
    height: 100%;
    margin-right: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;

    @include media() {
      margin-right: 5px;

      svg {
        width: 12px;
        height: 12px;
        margin-top: 2px;
      }
    }
  }
  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 30.09px;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;

    @include media() {
      font-size: 16px;
      line-height: 19.2px;
      text-overflow: initial;
      white-space: initial;
      padding: 0;
    }
  }
  &__period {
    color: $color_nativno_6;
    font-size: 18px;
    line-height: 16.92px;
    align-self: flex-end;

    @include media() {
      font-size: 10px;
      line-height: 9.4px;
      margin-top: 10px;
    }
  }
  &__info {
    font-size: 16px;
    line-height: 20.8px;

    &-retarget {
      margin-bottom: 50px;

      @include media() {
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
      }
    }

    @include media() {
      font-size: 10px;
      line-height: 13px;
    }
  }
}

