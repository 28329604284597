.progress {
  background-color: $color_nativno_8;
  width: 173px;
  height: 20px;
  border-radius: 8px;
  position: relative;
  margin-top: 20px;

  @include media() {
    height: 13px;
    // width: 125px;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
  }

  &__fill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color_nativno_2;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @include media() {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &_full {
      border-radius: 8px;

      @include media() {
        border-radius: 5px;
      }
    }
  }

  &__value {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 13.16px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media() {
      font-weight: 400;
      font-size: 10px;
      line-height: 9.4px;
    }
  }
}

.progress-city,
.progress-interest {
  margin-top: 9px;
  height: 10px;
  // width: 212px;
  width: 100%;
  border-radius: 9px;

  @include media() {
    // width: 110px;
    width: 100%;
  }

  .progress__fill {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;

    @include media() {
      border-radius: 6px;
    }

    &_full {
      border-radius: 9px;

      @include media() {
        border-radius: 6px;
      }
    }
  }
}
.progress-city {
  .progress__fill {
    background-color: $color_nativno_2;
  }
}
.progress-interest {
  .progress__fill {
    background-color: $color_nativno_11;
  }
}

.progresskpi {
  position: relative;
  background-color: $color_nativno_8;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  position: relative;
  margin-top: 60px;
  margin-bottom: 60px;

  @include media() {
    height: 13px;
    // width: 125px;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
  }

  &__fill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color_nativno_2;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @include media() {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &_full {
      border-radius: 8px;

      @include media() {
        border-radius: 5px;
      }
    }
  }

  &__value {
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 13.16px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media() {
      font-weight: 400;
      font-size: 10px;
      line-height: 9.4px;
    }
  }
}

.progreskpi {
  // change width
  position: relative;
  margin-top: 60px;
  margin-bottom: 60px;
  border: 1px solid var(--color-light-14);
  // background: var(--color-light-15);
  width: 100%;
  height: 40px;
  text-align: center;
  padding: 9px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--color-light-10);

  background-color: $color_nativno_8;
  border-radius: 20px;

  // add mixin

  // @include mobilemin {
  //   width: 90%;
  // }

  &__realcoverage {
    position: relative;
    z-index: 5;
    font-style: normal;
    font-weight: 500;
    font-size: 16;

    @include media() {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__accomplishment {
    position: absolute;
    width: 0;
    // background: var(--color-light-1);
    background: $color_nativno_2;
    height: 100%;
    top: 0px;
    left: 0px;
    transition: width 1s;
    border-radius: 20px 0 0 20px;
  }

  &__accomplishment-green {
    position: absolute;
    width: 0;
    background: $color_nativno_14;
    height: 100%;
    top: 0px;
    left: 0px;
    transition: width 1s;
    border-radius: 20px;
    // transition-delay: 0.3s;
  }

  &__scale {
    // change width
    position: relative;
    top: -44px;
    // display: flex;
    // justify-content: space-between;
    // opacity: 1;
    // transition: opacity 1s;
    width: 100%;

    // add mixin

    // @include mobilemin {
    //   width: 90%;
    // }

    &-count {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;

      &_last {
        right: 0;
      }
    }

    &_show {
      opacity: 1;
    }
  }

  &__percent {
    position: relative;
    text-align: center;

    margin-top: -35px;
    white-space: nowrap;

    &-line {
      width: 1px;
      height: 10px;
      // border-left: 1px solid var(--color-light-13);
      border-left: 1px solid #ffffff;
      // position: absolute;
      // bottom: 100%;
      // left: 50%;
      margin-bottom: 2px;
    }

    &-count {
      position: absolute;
      transform: translate(-50%, 0);
      color: var(--color-light-11);

      // add
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &-kpi {
      //  position: relative;
      color: var(--color-light-1);
      transform: translateX(50%);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      // top: -17px;

      @include media() {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
    }

    &-null {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      @include media() {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
      }
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   background-image: url(./line.svg);
    // }
  }

  &__coverage {
    position: absolute;
    top: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__coverage {
    &-wrap {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      overflow: hidden;
      opacity: 0;
      transition: opacity 1.5s;

      &_show {
        opacity: 1;
      }
    }

    &-value {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0%, -50%);
      max-width: var(--main-width);
      color: var(--color-light-16);
    }

    &-bottom {
      position: relative;
      color: var(--color-light-10);
      overflow: hidden;
      width: 100%;
    }
    &-top {
      position: absolute;
      color: var(--color-light-16);
      overflow: hidden;
      top: 0;
      left: 0;
    }
  }
}

.kpi {
  // position: relative;
  // left: 530px;
  top: -90px;
  position: absolute;
  right: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  @include media() {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  &__plan {
    position: absolute;
    transform: translateX(-50%);

    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;

    @include media() {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
