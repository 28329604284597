* {
  box-sizing: border-box;
  font-variant-ligatures: none;
}
html {
  padding: 0;
  margin: 0;
  height: 100%;
}
body {
  margin: 0;
  font-family: TTNorms, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-bottom: 50px; */
  background: #191c26;
  overflow-x: hidden;
}
