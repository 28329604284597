.auditory {
  display: flex;
  
  @include media() {
    flex-direction: column;
  }

  &__item {
    margin-top: 20px;
    
    @include media() {
      margin-top: 10px;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-name {
      font-size: 14px;
      line-height: 16.52px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;

      @include media() {
        font-size: 10px;
        font-weight: 700;
        line-height: 11.8px;
      }
    }
    &-value {
      font-size: 14px;
      line-height: 18.2px;

      @include media() {
        font-size: 10px;
        font-weight: 400;
        line-height: 13px;
      }
    }
  }
}