.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 80px);
  background-image: url("./../svg/content_bg.svg");
  background-size: cover;
  background-color: $color_nativno_3;
  background-position: center;

  @include media() {
    height: calc(100vh - 40px);
  }

  &__text {
    font-size: 32px;
    line-height: 30.09px;
    font-weight: 700;
    margin-top: 47px;
    text-transform: uppercase;
    
    @include media() {
      margin-top: 31px;
      font-size: 20px;
      line-height: 18.8px;
    }
  }

  @include media() {
    svg {
      height: 94px;
      width: 244px;
    }
  }
}