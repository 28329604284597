.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color_nativno_3;
  opacity: 0.98;

  &-hide {
    animation: hideLoader 0.5s ease 0s forwards;
    // transform: scale(0);
  }
  &-show {

  }
}

@keyframes hideLoader {
  from {
    // opacity: 0.98;
    transform: scale(0);
  }

  to {
    // opacity: 0;
    // width: 0;
    transform: scale(0);
  }
}