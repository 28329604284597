@font-face {
  font-family: 'TTNorms';
  font-weight: 400;
  src: local('TTNorms'), url(./fonts/TTNorms-Regular.woff) format('woff');
}
@font-face {
  font-family: 'TTNorms';
  font-weight: 500;
  src: local('TTNorms'), url(./fonts/TTNorms-Medium.woff) format('woff');
}
@font-face {
  font-family: 'TTNorms';
  font-weight: 700;
  src: local('TTNorms'), url(./fonts/TTNorms-Bold.woff) format('woff');
}