$color_nativno_6: #6A7084;
$color_nativno_4: #FFFFFF;
$color_nativno_2: #DD4242;
$color_nativno_8: #484F66;
$color_nativno_3: #191C26;
$color_nativno_5: #000000;
$color_nativno_16: #FFFFFF;
$color_nativno_7: #222632;
$color_nativno_11: #5282FF;
$color_nativno_14: #8916FF;
$color_nativno_20: #F85D5D;
// $color_nativno_: #;
// $color_nativno_: #;
// $color_nativno_: #;
$color_light_20: #FDD608;
