.snstats {
  display: flex;
  
  @include media() {
    flex-direction: column;
  }

  &__data {
    margin-left: 30px;
    width: 100%;

    @include media() {
      margin-left: 0;
    }

    .top-content__top {
      @include media() {
        margin-top: 10px;
      }
    }

    .parameter-text {
      &__text {
        font-size: 18px;
        line-height: 16.92px;

        @include media() {
          font-size: 10px;
          line-height: 9.4px;
          margin-top: 4px;
        }

        &-big {
          @include media() {
            font-size: 14px;
            line-height: 13.6px;
            font-weight: 700;
          }
        }
      }
      &__value {
        font-size: 36px;
        line-height: 33.85px;

        @include media() {
          font-size: 20px;
          line-height: 18.8px;
        }

        &-big {
          font-size: 70px;
          line-height: 65.81px;

          @include media() {
            font-size: 48px;
            line-height: 43.25px;
          }
        }
      }
    }
  }
}